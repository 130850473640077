<template>
  <div class="page">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: "UserMain",
};
</script>
